import React, { useEffect, useState } from "react";
import { Typography } from '@mui/material';
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, Pagination, Autoplay } from "swiper/modules";
import { motion } from 'framer-motion'; 
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import ExploreMoreButton from "../Buttons/ExploreMoreButton";
import memberService from "../../services/memberService";


const fadeInVariants = {
  hidden: { opacity: 0, x: 30 }, 
  visible: { opacity: 1, x: 0, transition: { duration: 0.8 } }, 
};

const MemberSlider = () => {
  const [members, setMembers] = useState([]);

  useEffect(() => {
     const loadData = async() => {
        const res = await memberService.fetchAll();
        // console.log("member", res);
        setMembers(res.data);
     }
     loadData();
  }, []);

  return (
    <section style={{ width: "100%", backgroundColor: "#003153" }}>
      <div className="container">
        <Typography
          variant="h2"
          color={'white'}
          className='mainH2TITLE'
          sx={{
            paddingTop:15,
            paddingBottom:2,
            textTransform: 'uppercase', 
            fontWeight: 'bold', 
          }}
        >
          Our Members
        </Typography>
        <div 
            className="brand-slider text-center"
            style={{ padding: "70px 0 70px 0" }}>
          <Swiper
            modules={[Autoplay]}
            spaceBetween={20}
            slidesPerView={6}
            autoplay={{
              delay: 0, // Removes delay for a continuous scroll
              disableOnInteraction: false,
            }}
            speed={5000} // Controls smooth scrolling speed
            loop={true} // Enables infinite looping
            freeMode={true} // Allows continuous smooth scrolling
            allowTouchMove={true} // Prevents manual sliding
            breakpoints={{
              1400: { slidesPerView: 6 },
              1024: { slidesPerView: 4 },
              768: { slidesPerView: 4 },  // Tablets
              640: { slidesPerView: 3 },  // Small tablets
              480: { slidesPerView: 2 },  // Mobile landscape
              320: { slidesPerView: 1 },  // Mobile portrait
            }}
            className="custom-swiper"
          >
            {members.map((member) => (
              <SwiperSlide key={member.id}>
                <a href={member.website || "#"}   
                   aria-label="Member website link"
                   target="_blank" 
                   rel="noopener noreferrer">
                <motion.div 
                  className="brand-box"
                  variants={fadeInVariants}
                  initial="hidden"
                  animate="visible"
                >
                    <img
                      src={member.image || "https://via.placeholder.com/150"}
                      alt={member.name ? `Photo of ${member.name}` : "Member"}
                      style={{ width: '60%' }}
                    />
                  
                </motion.div>
                </a>
              </SwiperSlide>
            ))}
          </Swiper>
        </div>
        <div
          style={{
            display: 'flex', 
            justifyContent: 'flex-end', 
            width: '100%',           
            paddingBottom: '90px'
          }}
        >
          <ExploreMoreButton
            href="/members" 
            label="Explore More" 
            aria-label="Explore More"
            color="white" 
            hoverColor="#f39c12"  
          />
        </div>
        {/* Adjust the pagination positioning */}
        <style jsx>{`
          .swiper-pagination {
            margin-top: 120px;  /* Move pagination down */
          }
        `}</style>
      </div>
    </section>
  );
};

export default MemberSlider;
