import React from "react";

const Loader = () => (
  <div 
    className="loaderGif"
    role="status"
    aria-label="Loading..."
    style={{
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      height: "100vh",
    }}
  >
    <img
      src="/loader.gif"
      alt="Loading animation"
      style={{ width: "30px" }}
    />
  </div>
);

export default Loader;
