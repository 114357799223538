import React, { useEffect, useState } from 'react';
import { Card, CardMedia, Typography } from '@mui/material';
import moment from 'moment';
import 'bootstrap/dist/css/bootstrap.min.css';
import ExploreMoreButton from '../Buttons/ExploreMoreButton';
import newsServices from '../../services/newsServices';

export default function News() {
  const [newsList, setNewsList] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const loadData = async() => {
        try {
            const res = await newsServices.fetchFrontNews();
            //console.log('news', res);
            setNewsList(res.data);
            setLoading(false); // Set loading to false when data is fetched
        } catch (error) {
            console.error("Error fetching news:", error);
            setLoading(false);
        }
    }
    loadData();
  }, []);

  const featuredNews = newsList.length > 0 ? newsList[0] : null;
  const newsItems = newsList.length > 1 ? newsList.slice(1, 4) : []; // Ensure there are at least 2 items

  return (
    <section style={{ width: '100%', backgroundColor: '#fff', padding: '80px 0' }} className='NewsFrontSection'>
      <div className="container">
        <Typography
          variant="h2"
          color={'black'}
          className='mainH2TITLE'
          sx={{
            paddingTop: 3,
            paddingBottom: 10,
            textTransform: 'uppercase',
            fontWeight: 'bold',
          }}
        >
          News AND EVENTS
        </Typography>
      </div>

      <div className="container" style={{ display: 'flex', flexDirection: 'row', gap: '100px', flexWrap: 'wrap' }}>
        {featuredNews && (
          <div style={{ flex: 1, minWidth: '300px', marginBottom: '20px' }}>
            <Card sx={{ height: '100%', position: 'relative', color: 'white' }}>
              <div style={{ position: 'relative', height: '565px', overflow: 'hidden' }}>
                <CardMedia
                  sx={{
                    height: '100%',
                    width: '100%',
                    objectFit: 'cover',
                    filter: 'brightness(0.7)',
                  }}
                  image={featuredNews.image || 'https://via.placeholder.com/150'}
                  title="Featured News"
                />

                <div
                  style={{
                    position: 'absolute',
                    top: '20px',
                    right: '20px',
                   
                    color: '#fff',
                    fontWeight: 'bold',
                    borderRadius: '8px',
                    zIndex: 1,
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                      background: "#ffca08",
                      padding: "10px",
                      borderRadius: '4px'
                    }}
                  >
                    <Typography
                      variant="h3"
                      color="#fff"
                      sx={{ fontWeight: "bold", lineHeight: "1" , fontSize: "3.4rem !important",}}
                    >
                      {new Date(featuredNews.date).getDate()}
                    </Typography>
                    <Typography
                      variant="body1"
                      color="#fff"
                      sx={{ fontSize: "1.4rem !important", lineHeight: "1.2  !important" }}
                    >
                      {new Date(featuredNews.date).toLocaleString("default", {
                        month: "short",
                      })}
                    </Typography>
                  </div>
                </div>

                <div
                className='NewsContrast'
                  style={{
                    position: 'absolute',
                    bottom: 0,
                    left: 0,
                    right: 0,
                    margin: '30px',
                    borderRadius: '4px',
                    padding: '25px',
                    background: '#003153',
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'flex-end',
                    textAlign: 'left',
                    width: '100%',
                  }}
                >
                  <Typography
                    variant="p"
                    component="a"
                    aria-label="News Details" 
                    href={`/news/${featuredNews.slug}`}
                    gutterBottom
                    sx={{ textDecoration: 'none', color: 'inherit', paddingRight: '10px' }}
                    className='newsFeaturedTitle'
                  >
                    {featuredNews.title}
                  </Typography>

                { 
                    featuredNews.location && (
                      <div style={{ display: 'flex', alignItems: 'center', marginBottom: '8px', color: 'white' }}>
                        <i className="fa fa-clock-o" aria-hidden="true" style={{ fontSize: '1.3em !important' }}></i>
                        <Typography variant="body1" 
                                    style={{ fontSize: '1.1em  !important', 
                                            color: 'white', 
                                            marginLeft: '0.4em'
                                          }}>
                          {featuredNews.location || 'Location not available'}
                        </Typography>
                      </div>
                  )}


                 { 
                    featuredNews.time && (
                        <div style={{ display: 'flex', alignItems: 'center', color: 'white' }}>
                          <i className="fa fa-map-marker" aria-hidden="true" style={{ fontSize: '1.3em  !important' }}></i>
                          <Typography variant="body1" 
                                      style={{ fontSize: '1.1em  !important', 
                                      color: 'white', 
                                      marginLeft: '0.4em'
                                    }}>
                            {featuredNews.time || 'Time not available'}
                          </Typography>
                        </div>
                  )}

                </div>
              </div>
            </Card>
          </div>
        )}

        <div style={{ flex: 1, minWidth: '300px' }}>
          {loading ? (
            <Typography variant="h6" color="textSecondary">
              Loading...
            </Typography>
          ) : (
            newsItems.length > 0 ? (
              newsItems.map((news) => (
                <div
                  className='NewsContrast'
                  key={news.id}
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    marginBottom: '60px',
                    background: '#fff',
                    borderRadius: '8px',
                    overflow: 'hidden',
                    flexDirection: 'row',
                    padding: '0 16px',
                    gap: '26px',
                    width: '100%',
                  }}
                >
                  <div style={{ flex: 0, minWidth: '100px', textAlign: 'center' }}>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                      background: "#093f89",
                      padding: "30px 10px",
                      borderRadius: '4px'
                    }}
                  >
                    <Typography
                      variant="h3"
                      color="#fff"
                      sx={{ fontWeight: "bold  !important", lineHeight: "1  !important", fontSize: "3.4rem !important" }}
                    >
                      {new Date(news.date).getDate()}
                    </Typography>
                    <Typography
                      variant="body1"
                      color="#fff"
                      sx={{ fontSize: "1.4rem  !important", lineHeight: "1.2  !important" }}
                    >
                      {new Date(news.date).toLocaleString("default", {
                        month: "short",
                      })}
                    </Typography>
                  </div>
                  </div>

                  <div style={{ flex: 1 }}>
                    <Typography
                      variant="h5"
                      component="a"
                      href={`/news/${news.slug}`}
                      sx={{ textDecoration: 'none', color: 'inherit'}}
                      className='newsFrontTitle'
                    >
                      {news.title} 
                    </Typography>
                    <div
                      style={{
                        display: 'flex',
                        alignItems: 'center',
                        marginBottom: '8px',
                        marginTop: '8px',
                        color: 'black',
                      }}
                    >
                      {/* <i className="fa fa-clock-o" aria-hidden="true" style={{ fontSize: '1.3em' }}></i>
                      <Typography variant="body1" style={{ fontSize: '1.1em', color: 'black', marginLeft: '0.4em' }}>
                        {news.location || 'Location not available'}
                      </Typography> */}
                    </div>

                    {/* <div style={{ display: 'flex', alignItems: 'center', color: 'black' }}>
                      <i className="fa fa-map-marker" aria-hidden="true" style={{ fontSize: '1.3em' }}></i>
                      <Typography variant="body1" style={{ fontSize: '1.1em', color: 'black', marginLeft: '0.4em' }}>
                        {news.time || 'Time not available'}
                      </Typography>
                    </div> */}
                  </div>
                </div>
              ))
            ) : (
              <Typography variant="h6" color="textSecondary">
                No news available.
              </Typography>
            )
          )}
        </div>
      </div>

      <div className="container d-flex justify-content-end">
        <ExploreMoreButton 
          href="/news" 
          rel="noopener noreferrer"
          aria-label="Explore More"
          label="Explore More" 
          color="black" 
          hoverColor="#f39c12"
        />
      </div>
    </section>
  );
}
