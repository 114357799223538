import apiClient from "./apiClient";

const steeringCommitteeService = {

    fetchAll: async () => {
        try {
            const response = await apiClient.get('/steering-committee');
            return response.data;
        } catch (error) {
            console.error('Error fetching data:', error);
            throw error;
        }
    },

    fetchBySlug: async (slug) => {
        try {
            const response = await apiClient.get(`/steering-committee/${slug}`);
            return response.data;
        } catch (error) {
            console.error('Error fetching data by ID:', error);
            throw error;
        }

    }


}

export default steeringCommitteeService;