import React, { useEffect, useState, useContext } from 'react';
import { Helmet } from 'react-helmet-async';
import Breadcrumb from '../components/Breadcrumb/Breadcrumb';
import { SettingsContext } from '../context/SettingsContext';
import teamService from '../services/teamServices';

const SecretariatStaffs = () => {
  const [content, setContent] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const { settings } = useContext(SettingsContext);

  useEffect(() => {
    const loadData = async () => {
      try {
        setLoading(true);
        const res = await teamService.fetchAll();
        setContent(res.data);
      } catch (err) {
        setError('Failed to load team data.');
      } finally {
        setLoading(false);
      }
    };
    loadData();
  }, []);

  return (
    <>
      <Helmet>
        <title>Secretariat Staffs</title>
      </Helmet>

      <Breadcrumb title="Secretariat Staffs" backgroundImage={settings?.data?.[0]?.team_bg || 'fallback-image.jpg'} />

      <div className="container mt-4" style={{ paddingTop: '40px', paddingBottom: '100px' }}>
        {loading ? (
          <p>Loading...</p>
        ) : error ? (
          <p>Error loading team members: {error}</p>
        ) : (
          <div className="row row-cols-1 row-cols-sm-2 row-cols-md-3 g-5">
            {content.map((member, index) => (
              <div className="col" key={index}>
                <div className="card text-center border-0 shadow-sm">
                  <div className="card-body">
                    <img
                      src={member.image}
                      alt={member.name}
                      className="rounded-circle img-fluid mb-3"
                      style={{ width: '250px', height: '250px', objectFit: 'cover', marginTop: '60px' }}
                    />
                    <h5 className="card-title" style={{ marginTop: '30px' }}>{member.name}</h5>
                    <p className="card-text text-muted">{member.designation}</p>
                    <p className="card-text text-muted">{member.email}</p>
                    <div className="d-flex justify-content-center gap-3">
                      {member.socials?.facebook && (
                        <a href={member.socials.facebook} className="text-primary" target="_blank" rel="noopener noreferrer">
                          <i className="fa-brands fa-facebook-f"></i>
                        </a>
                      )}
                      {member.socials?.twitter && (
                        <a href={member.socials.twitter} className="text-info" target="_blank" rel="noopener noreferrer">
                          <i className="fa-brands fa-twitter"></i>
                        </a>
                      )}
                      {member.socials?.linkedin && (
                        <a href={member.socials.linkedin} className="text-primary" target="_blank" rel="noopener noreferrer">
                          <i className="fa-brands fa-linkedin-in"></i>
                        </a>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>
        )}
      </div>
    </>
  );
};

export default SecretariatStaffs;
