import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import ModuleBreadcrumbs from '../Breadcrumb/ModuleBreadcrumbs'; // Correct relative import
import moment from 'moment';
import { Helmet } from 'react-helmet-async';
import storyService from '../../services/storyServices';


const StoryDetail = () => {
  const { slug } = useParams();
  const [content, setContent] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);


  useEffect(() => {
    const fetchStoryBySlug = async (slug) => {
      try {
        setLoading(true);
        const story = await storyService.fetchBySlug(slug); // Fetch story by slug
        // console.log('story_data', story);
        setContent(story);
        setError(null); // Clear any previous errors
      } catch (err) {
        console.error('Error fetching story by slug:', err);
        setError('Failed to load the story.');
      } finally {
        setLoading(false);
      }
    };

    if (slug) {
      fetchStoryBySlug(slug);
    }
  }, [slug]);

  const updateIframeWidth = (htmlContent) => {
    if (!htmlContent) return ''; // Return an empty string if htmlContent is null or undefined
    return htmlContent.replace(
      /<iframe[^>]*>/g,
      (match) =>
        match
          .replace(/width="\d+"/, 'width="100%"')
          .replace(/height="\d+"/, 'height="560"')
    );
  };

  if (loading) return <div></div>;
  if (error) return <div>{error}</div>;

  return (
    <>

         <Helmet>
            <title>{content.title}</title>
          </Helmet>
    
        <ModuleBreadcrumbs
            title={content.title}
            backgroundImage={content.image}
        />

        <div className="container mt-4"> 
        <table className="content-info-table" style={{ width: '50%', marginTop: '40px', marginBottom: '40px', borderCollapse: 'collapse' }}>
           
            <tbody>
                <tr>
                    {content.content_creator && (
                        <>
                        <td style={{ padding: '8px' }}><strong>Author:</strong></td>
                        <td style={{ padding: '8px' }}>{content.content_creator}</td>
                        </>
                    )}
                    </tr>

                    <tr>
                    {content.country_featured && (
                        <>
                        <td style={{ padding: '8px' }}><strong>Country:</strong></td>
                        <td style={{ padding: '8px' }}>{content.country_featured}</td>
                        </>
                    )}
                    </tr>

                    <tr>
                    {content.resource_type && (
                        <>
                        <td style={{ padding: '8px' }}><strong>Resource Type:</strong></td>
                        <td style={{ padding: '8px' }}>{content.resource_type}</td>
                        </>
                    )}
                    </tr>

                    <tr>
                    {content.consent_received && (
                        <>
                        <td style={{ padding: '8px' }}><strong>Consent Received:</strong></td>
                        <td style={{ padding: '8px' }}>{content.consent_received}</td>
                        </>
                    )}
                    </tr>

                    <tr>
                    {content.special_instructions && (
                        <>
                        <td style={{ padding: '8px' }}><strong>Special Instructions:</strong></td>
                        <td style={{ padding: '8px' }}>{content.special_instructions}</td>
                        </>
                    )}
                    </tr>
            </tbody>
        </table>


<p  className='StroyInnerDate'>
<i class="fa fa-calendar-check-o" aria-hidden="true" style={{marginRight: 5, display:'inline-block'}}></i>
   {moment(content.date).format('MMM D, YYYY')}
</p>
        <blockquote style={{ marginBottom:40}}>
          <h3 style={{ lineHeight: '1.6', fontSize: '2.5em !important' }}>{content.summary}</h3>
      </blockquote>

        <div
            style={{ marginBottom:40}}
            className="content-details"
            dangerouslySetInnerHTML={{ __html: updateIframeWidth(content.description) }}
        />

        </div>
    </>
  );
};

export default StoryDetail;
