import React, { useEffect, useState, useContext } from 'react';
import { useParams } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';
import Breadcrumb from '../components/Breadcrumb/Breadcrumb';
import DataTable from 'react-data-table-component'; 
import { SettingsContext } from '../context/SettingsContext';
import memberService from '../services/memberService';

const MemberINGO = () => {
  const [content, setContent] = useState(null);
  const [searchQuery, setSearchQuery] = useState(''); 
  const [currentPage, setCurrentPage] = useState(1);
  const rowsPerPage = 20; // Define rows per page

  const { settings } = useContext(SettingsContext);

  useEffect(() => {
    const loadData = async() => {
      const res = await memberService.fetchAll();
      setContent(res.data);
    }
    loadData();
  }, [])


  const handleSearchChange = (event) => {
    setSearchQuery(event.target.value);
    setCurrentPage(1); // Reset to the first page on new search
  }

  const filteredContent = content?.filter((item) =>
    (item.org_name?.toLowerCase().includes(searchQuery.toLowerCase()) || '') ||
    (item.sector_member?.toLowerCase().includes(searchQuery.toLowerCase()) || '') ||
    (item.name1?.toLowerCase().includes(searchQuery.toLowerCase()) || '') ||
    (item.position1?.toLowerCase().includes(searchQuery.toLowerCase()) || '')
  );

  const columns = [
    {
      name: 'S.N',
      selector: (_, index) => (currentPage - 1) * rowsPerPage + index + 1,
      sortable: true,
      width:"90px",
      textAlign: "center",
      style: {
        width: '50px',
        textAlign: 'center',
        padding: '0 5px',
      },
    },
    {
      name: 'Name',
      selector: row => row.org_name,
      sortable: true,
      wrap: true,
      style: {
        width: '200px',
        textOverflow: 'clip', 
        whiteSpace: 'normal', 
        overflow: 'visible', 
        textAlign: 'left',
      },
    },
    {
      name: 'Sector',
      selector: row => row.sector_member,
      wrap: true,
      sortable: true,
      style: {
        width: '150px',
        wordWrap: 'break-word', 
        whiteSpace: 'normal', 
        overflow: 'visible', 
        textOverflow: 'clip', 
        textAlign: 'left',
      },
    },
    {
      name: 'Contact Person',
      selector: row => row.name1,
      sortable: true,
      wrap: true,
      style: {
        width: '150px', 
        wordWrap: 'break-word', 
        whiteSpace: 'normal', 
        overflow: 'visible', 
        textOverflow: 'clip',
        textAlign: 'left',
      },
    },
    {
      name: 'Designation',
      selector: row => row.position1,
      sortable: true,
      wrap: true,
      style: {
        width: '150px', 
        wordWrap: 'break-word', 
        whiteSpace: 'normal', 
        overflow: 'visible', 
        textOverflow: 'clip', 
        textAlign: 'left',
      },
    },
    {
      name: 'Logo',
      selector: row => (
        <a href={row.website} target="_blank" rel="noopener noreferrer">
          <img src={row.image} alt={row.org_name} width="100" />
        </a>
      ),
      sortable: false,
      style: {
        width: '100px',
        textAlign: 'left',
      },
    },
  ];

  const customStyles = {
    table: {
      style: {
        tableLayout: 'fixed', 
      },
    },
    headCells: {
      style: {
        fontSize: '18px', 
        fontWeight: 'bold', 
        color: '#093f89',
        backgroundColor: '#f5f5f5', 
        textAlign: 'center',
        padding: '12px', 
      },
    },
    cells: {
      style: {
        paddingTop: '30px',
        paddingBottom: '30px',
        fontSize: '16px', 
        textAlign: 'left',
      },
    },
    rows: {
      style: {
        '&:hover': {
          backgroundColor: '#f0f8ff', 
        },
      },
    },
    
  };

  return (
    <>
      <Helmet>
        <title>Members INGOs</title>
      </Helmet>

      <Breadcrumb title='Members INGOs' backgroundImage={settings?.data?.[0]?.team_bg} />

      <div className="container mt-4"
           style={{
            paddingTop:"40px",
            paddingBottom:"40px",
           }}>

            <div className="mb-5 d-flex justify-content-end">
                <div className="input-group" style={{ maxWidth: '300px' }}>
                    <div className="input-group-prepend">
                    <span className="input-group-text" style={{ backgroundColor: 'transparent', border: 'none' }}>
                        <i className="fa fa-search"></i>
                    </span>
                    </div>
                    <input
                    type="text"
                    className="form-control form-control-sm"
                    placeholder="Search"
                    value={searchQuery}
                    onChange={handleSearchChange}
                    />
                </div>
            </div>

        {filteredContent && filteredContent.length > 0 ? (
          <DataTable
            title="Members INGOs"
            columns={columns}
            data={filteredContent}
            pagination
            paginationPerPage={rowsPerPage}
            paginationTotalRows={filteredContent.length}
            paginationDefaultPage={currentPage}
            onChangePage={(page) => setCurrentPage(page)}
            highlightOnHover
            striped
            responsive
            customStyles={customStyles}
            noHeader
            paginationComponentOptions={{
              rowsPerPageText: (
                <span style={{ fontWeight: 'bold' }}>
                  Rows per page:
                </span>
              ),
            }}
        />
        ) : (
          <div className="text-center"></div>
        )}
      </div>
    </>
  );
};



export default MemberINGO;
