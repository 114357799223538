import apiClient from './apiClient';

const storyService = {

  fetchFrontStory: async () => {
    try {
      const response = await apiClient.get('/homepage-stories');
      return response.data;
    } catch (error) {
      console.error('Error fetching data:', error);
      throw error;
    }
  },

  fetchAll: async (page = 1) => {
    try {
      const response = await apiClient.get(`/stories?page=${page}`);
      return response.data;
    } catch (error) {
      console.error('Error fetching data:', error);
      throw error;
    }
  },

  fetchBySlug: async (slug) => {
    try {
      const response = await apiClient.get(`/story/${slug}`);
      return response.data;
    } catch (error) {
      console.error('Error fetching data by ID:', error);
      throw error;
    }
  },
  
};

export default storyService;
