import React, { useEffect, useState, useContext } from 'react';
import { useParams } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';
import Breadcrumb from '../components/Breadcrumb/Breadcrumb';
import { SettingsContext } from '../context/SettingsContext';
import steeringCommitteeService from '../services/steeringCommitteeServices';

const ContentPage = () => {
  const { slug } = useParams();
  const [content, setContent] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const { settings } = useContext(SettingsContext);

  useEffect(() => {
    const fetchBySlug = async (slug) => {
      try {
        setLoading(true);
        const news = await steeringCommitteeService.fetchBySlug(slug); // Fetch story by slug
        // console.log('story_data', story);
        setContent(news);
        setError(null); // Clear any previous errors
      } catch (err) {
        console.error('Error fetching news by slug:', err);
        setError('Failed to load the news.');
      } finally {
        setLoading(false);
      }
    };

    if (slug) {
      fetchBySlug(slug);
    }
  }, [slug]);

  const updateIframeWidth = (htmlContent) => {
    return htmlContent.replace(
      /<iframe[^>]*>/g,
      (match) => match.replace(/width="\d+"/, 'width="100%"').replace(/height="\d+"/, 'height="560"')
    );
  };

  if (loading) return <div></div>;  // Fixed loading JSX

  if (error) return <div>{error}</div>;

  if (!content) return <div>No content available</div>;  // Handle case where content is null

  return (
    <>
      <Helmet>
        <title>{content.name}</title>
        <meta name="description" content={content.education || 'No description available'} />
        <meta name="keywords" content={content.name || 'steering, committee, content'} />
        {content.image && <meta property="og:image" content={content.image} />}
      </Helmet>

      {/* Breadcrumb */}
      {/* <Breadcrumb title="Steering Committee" backgroundImage={settings?.data?.[0]?.team_bg} /> */}

      {/* Content Section */}
      <div className="container steering_comittee_container" style={{ paddingTop: '20px', paddingBottom: '55px' }}>
        <div className="content-header">
          <div className="row">
            {/* Image on the left */}
            <div className="col-md-4">
              {content.image && (
                <img
                  src={content.image}
                  alt={content.name}
                  className="img-fluid"
                  style={{ borderRadius: '8px' }}
                />
              )}
            </div>

            {/* Content on the right */}
            <div className="col-md-8 steering_comittee_container_right">
              <h2>{content.name}</h2>
              <p>{content.designation}</p>
              Education:
              <div
                className="content-details"
                dangerouslySetInnerHTML={{ __html: updateIframeWidth(content.education) }}
              />
              <p></p>
               Organization :
              <p>{content.organization} ({content.org_designation})</p>
              Short Info:
              <div
                className="content-details"
                dangerouslySetInnerHTML={{ __html: updateIframeWidth(content.work_experience) }}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ContentPage;
