import React, { useState, useEffect } from "react";
import Modal from 'react-modal';
import popupService from "../../services/popupServices";

Modal.setAppElement('#root');

const HomePagePopup = () => {
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [popupData, setPopupData] = useState(null);

    // useEffect(() => {
    //     const loadData = async () => {
    //         try {
    //             const res = await popupService.fetchAll(); 
    //           //  console.log("popup data:", res);
    //             if (res.success && res.data.length > 0) {
    //                 setPopupData(res.data[0]);
    //                 setIsModalOpen(true); 
    //             } else {
    //                 console.log('No popup data available');
    //             }
    //         } catch (error) {
    //             console.error('Error fetching popup data:', error);
    //         }
    //     };

    //     loadData(); 
    // }, []); 

    // const closeModal = () => {
    //     setIsModalOpen(false); // Close the modal when the button is clicked
    // };


    useEffect(() => {
        const loadData = async () => {
            const res = await popupService.fetchAll(); 
            // console.log("popup data:", res);
            if (res.data.length > 0) {
                setPopupData(res.data[0]);
                setIsModalOpen(true); 
            } else {
                console.log('No popup data available');
            }
        };

        loadData(); 
    }, []); 

    const closeModal = () => {
        setIsModalOpen(false); 
    };
   

    return (
        <div>
            {popupData && (
                <Modal 
                    isOpen={isModalOpen} 
                    onRequestClose={closeModal} 
                    contentLabel="Homepage Popup"
                    ariaHideApp={false}
                    style={{
                        overlay: {
                            backgroundColor: 'rgba(0, 0, 0, 0.5)', 
                        },
                        content: {
                            width: '60%',
                            height: 'auto',
                            maxWidth: '600px', 
                            margin: 'auto',
                            padding: '20px',
                            borderRadius: '10px',
                            backgroundColor: '#fff', 
                            overflow: 'hidden',
                            zIndex: '9999999999999999999999999',
                            position: 'relative'
                        }
                    }}
                >
                 
                    
                    <img 
                        src={popupData.image} 
                        alt={popupData.image_name || 'Popup Image'} 
                        style={{ width: '100%', height: 'auto', marginBottom: '15px' }} 
                    />
                    <button onClick={closeModal}>Close</button>
                </Modal>
            )}
        </div>
    );
};

export default HomePagePopup;
