import apiClient from "./apiClient";

const vacancyService = {

    fetchFrontVacancy: async () => {
        try {
          const response = await apiClient.get('/homepage-career');
          return response.data;
        } catch (error) {
          console.error('Error fetching data:', error);
          throw error;
        }
      },

    fetchAll: async () => {
        try {
            const response = await apiClient.get(`/career`);
            return response.data;
        } catch (error) {
            console.error('Error fetching data:', error);
            throw error;
        }
    } 

}

export default vacancyService;