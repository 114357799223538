import apiClient from "./apiClient";

const policyService = {

    fetchAll: async () => {
        try {
            const response = await apiClient.get('/policies');
            // console.log("API Response Structure:", response); // Log the entire response
            return response.data;
        } catch (error) {
            console.error('Error fetching data:', error);
            throw error;
        }
    }, 

    fetchBySlug: async (slug, password = null) => {
        try {
            const response = await apiClient.get(`/policy/${slug}`, {
                params: password ? { password } : {}, // Include password if provided
            });
            return response.data;
        } catch (error) {
            console.error('Error fetching data by slug:', error);
            throw error;
        }
    }
    

}

export default policyService;
