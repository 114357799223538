import apiClient from './apiClient';

const galleryService = {

  
  fetchAll: async () => {
    try {
      const response = await apiClient.get(`/gallery`);
      return response.data;
    } catch (error) {
      console.error('Error fetching data:', error);
      throw error;
    }
  },

  // fetchBySlug: async (slug) => {
  //   try {
  //     const response = await apiClient.get(`/gallery/${slug}`);
  //     return response.data;
  //   } catch (error) {
  //     console.error('Error fetching data by ID:', error);
  //     throw error;
  //   }
  // },


  fetchBySlug: async (slug) => {
    try {
      const response = await apiClient.get(`/gallery/${slug}`);
      console.log("API response raw data:", response.data);
      return response.data;
    } catch (error) {
      console.error("Error fetching data by slug:", error);
      throw error;
    }
  }
  

//   fetchBySlug: async (slug) => {
//     try {
//         const response = await apiClient.get(`/news/${slug}`);
//         return response.data;
//     } catch (error) {
//         console.error('Error fetching data by slug:', error);
//         throw error;
//     }
// }
  
};

export default galleryService;
