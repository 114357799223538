import React, { useState, useEffect } from "react";

const AccessibilityToolbar = () => {
  const [fontSize, setFontSize] = useState(16);
  const [isHighContrast, setIsHighContrast] = useState(false);
  const [isOpen, setIsOpen] = useState(false);

  // Handle text-to-speech for the entire page or specific section
  const handleSpeak = () => {
    if ('speechSynthesis' in window) {
      // Extract text from the specific section (about_area_para)
      const aboutText = document.querySelector('body').innerText;

      // Create a SpeechSynthesisUtterance to read the content
      const utterance = new SpeechSynthesisUtterance(aboutText);
      utterance.lang = 'en-US';  // Language preference
      utterance.rate = 1;        // Speech rate (1 is the normal speed)
      window.speechSynthesis.speak(utterance);
    } else {
      alert("Sorry, your browser does not support text-to-speech.");
    }
  };

  useEffect(() => {
    document.documentElement.style.setProperty("--global-font-size", `${fontSize}px`);
  }, [fontSize]);

  useEffect(() => {
    if (isHighContrast) {
      document.body.classList.add("high-contrast");
    } else {
      document.body.classList.remove("high-contrast");
    }
  }, [isHighContrast]);

  return (
    <div className={`accessibility-menu ${isOpen ? "open" : ""}`} role="menu">
      <button
        className="toggle-button"
        aria-label="Toggle Accessibility Menu"
        onClick={() => setIsOpen(!isOpen)}
      >
        <i className="fa fa-cog" aria-hidden="true"></i>
      </button>

      {isOpen && (
        <div className="toolbar-content">
          <h4>Accessibility</h4>
          <button onClick={() => setFontSize((prevSize) => prevSize + 2)} aria-label="Increase font size">
            A+
          </button>
          <button onClick={() => setFontSize((prevSize) => Math.max(12, prevSize - 2))} aria-label="Decrease font size">
            A-
          </button>
          <button
            onClick={() => setIsHighContrast(!isHighContrast)}
            aria-label="Toggle High Contrast Mode"
          >
            {isHighContrast ? "Normal Mode" : "High Contrast"}
          </button>
          <button 
            onClick={handleSpeak}
            aria-label="Read Website Content"
          >
            📢 Read Content
          </button>
        </div>
      )}
    </div>
  );
};

export default AccessibilityToolbar;
