import React, { useEffect, useState, useContext } from 'react';
import { Helmet } from 'react-helmet-async';
import Breadcrumb from '../Breadcrumb/Breadcrumb';
import DataTable from 'react-data-table-component'; 
import { SettingsContext } from '../../context/SettingsContext';
import policyService from '../../services/policyServices';

const PolicyList = () => {
//   const [content, setContent] = useState(null);
//   const [loading, setLoading] = useState(true);
//   const [error, setError] = useState(null);
//   const [searchQuery, setSearchQuery] = useState(''); 
//   const { settings } = useContext(SettingsContext);

    const [content, setContent] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [searchQuery, setSearchQuery] = useState(''); 
    const { settings } = useContext(SettingsContext);

  useEffect(() => {
    const loadData = async () => {
      setLoading(true);
      setError(null);  // Reset error
      try {
        const res = await policyService.fetchAll();
        setContent(res.data);
      } catch (err) {
        setError('Failed to load policies');
      } finally {
        setLoading(false);
      }
    }
    loadData();
  }, []);

  const handleSearchChange = (event) => {
    setSearchQuery(event.target.value);
  };

  const filteredContent = content?.filter((item) =>
    (item.title?.toLowerCase().includes(searchQuery.toLowerCase()) || '') ||
    (item.content?.toLowerCase().includes(searchQuery.toLowerCase()) || '')
  );

  console.log('Filtered Content list:', filteredContent);


  const columns = [
    {
      name: 'S.N',
      selector: (row, index) => index + 1,
      sortable: true,
      width: "90px",
      textAlign: "center",
      style: {
        width: '50px',
        textAlign: 'center',
        padding: '0 5px',
      },
    },
    {
      name: 'Name',
      selector: row => (
        <a href={`policy/${row.slug}`} className='steeing_commiittee_a'  rel="noopener noreferrer">
          {row.title}
          {row.is_password_protected && <span className="text-danger"> (Protected)</span>}
        </a>
      ),
      sortable: true,
      wrap: true,
      style: {
        width: '200px',
        textOverflow: 'clip', 
        whiteSpace: 'normal', 
        overflow: 'visible', 
        textAlign: 'left',
      },
    },
    {
      name: 'Content',
      selector: row => row.content,
      sortable: true,
      wrap: true,
      style: {
        width: '200px', 
        wordWrap: 'break-word', 
        whiteSpace: 'normal', 
        overflow: 'visible', 
        textOverflow: 'clip',
        textAlign: 'left',
      },
    }
  ];

  const customStyles = {
    table: {
      style: {
        tableLayout: 'fixed', 
      },
    },
    headCells: {
      style: {
        fontSize: '18px', 
        fontWeight: 'bold', 
        color: '#093f89',
        backgroundColor: '#f5f5f5', 
        textAlign: 'center',
        padding: '12px', 
      },
    },
    cells: {
      style: {
        paddingTop: '30px',
        paddingBottom: '30px',
        fontSize: '16px', 
        textAlign: 'left',
      },
    },
    rows: {
      style: {
        '&:hover': {
          backgroundColor: '#f0f8ff', 
        },
      },
    },
  };

  return (
    <>
      <Helmet>
        <title>AIN Policies</title>
      </Helmet>

      <Breadcrumb title='AIN Policies' backgroundImage={settings?.data?.[0]?.project_bg} />

      <div className="container mt-4" style={{ paddingTop: "40px", paddingBottom: "40px" }}>
        <div className="mb-5 d-flex justify-content-end">
          <div className="input-group" style={{ maxWidth: '300px' }}>
            <div className="input-group-prepend">
              <span className="input-group-text" style={{ backgroundColor: 'transparent', border: 'none' }}>
                <i className="fa fa-search"></i>
              </span>
            </div>
            <input
              type="text"
              className="form-control form-control-sm"
              placeholder="Search"
              value={searchQuery}
              onChange={handleSearchChange}
            />
          </div>
        </div>

        {loading && <div className="text-center">Loading...</div>}

        {error && <div className="text-center text-danger">{error}</div>}

        {filteredContent && filteredContent.length > 0 ? (
          <DataTable
            title="Steering Committee"
            columns={columns}
            data={filteredContent}
            pagination={false}
            paginationPerPage={20} 
            highlightOnHover
            striped
            responsive
            customStyles={customStyles}
            noHeader
          />
        ) : (
          !loading && <div className="text-center">No policies available</div>
        )}
      </div>
    </>
  );
};

export default PolicyList;
