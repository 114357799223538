import apiClient from './apiClient';

const bannerService = {
  fetchAll: async () => {
    try {
      const response = await apiClient.get('/banners');
      return response.data;
    } catch (error) {
      
    }
  },

};

export default bannerService;
