import React, { useEffect, useState, useContext } from 'react';
import { Box, Card, CardMedia, Typography, Button, useMediaQuery, ThemeProvider, createTheme, Pagination } from '@mui/material';
import 'bootstrap/dist/css/bootstrap.min.css';
import { motion } from 'framer-motion';
import moment from 'moment';
import { Helmet } from 'react-helmet-async';
import Breadcrumb from '../Breadcrumb/Breadcrumb';
import { SettingsContext } from '../../context/SettingsContext';
import newsService from '../../services/newsServices';


const News = () => {
  const [newsArticles, setNewsArticles] = useState([]);
  const { settings } = useContext(SettingsContext);
  const [currentPage, setCurrentPage] = useState(1); // Current page state
  const [totalPages, setTotalPages] = useState(1); // Total pages state
  const [error, setError] = useState(null);


  // const [storyArticles, setStoryArticles] = useState([]);
  //   const { settings } = useContext(SettingsContext);
  //   const [currentPage, setCurrentPage] = useState(1); // Current page state
  //   const [totalPages, setTotalPages] = useState(1); // Total pages state
  //   const [error, setError] = useState(null);

  // Fetch stories data based on page number
  // useEffect(() => {
  //   const loadData = async () => {
  //     try {
  //       const res = await newsService.fetchAll(currentPage); // Fetch paginated data
  //       console.log('API Response:', res.data); // Debugging output
  //       setNewsArticles(res.data.data || []); // Ensure it's an array
  //       setTotalPages(res.data.last_page || 1); // Ensure it gets total pages
  //     } catch (err) {
  //       console.error('Error fetching news:', err);
  //       setError('Failed to load news.');
  //     }
  //   };
  //   loadData();
  // }, [currentPage]);


  useEffect(() => {
    const loadData = async () => {
      try {
        const res = await newsService.fetchAll(currentPage); // Pass current page to the service
        setNewsArticles(res.data.data); // Set stories
        setTotalPages(res.data.last_page); // Set total pages
      } catch (err) {
        setError('Failed to load news.');
      }
    };
    loadData();
  }, [currentPage]); // Fetch stories whenever currentPage changes


  const isMobile = useMediaQuery((theme) => theme.breakpoints.down('sm'));
  const isTablet = useMediaQuery((theme) => theme.breakpoints.between('sm', 'md'));
  const columns = isMobile ? 1 : isTablet ? 2 : 3;

  const cardVariants = {
    hidden: { opacity: 0, y: 50 },
    visible: { opacity: 1, y: 0, transition: { duration: 0.6 } },
  };

  return (
    <>
      <Helmet>
        <title>News & Events</title>
      </Helmet>

      <Breadcrumb title="News & Events" backgroundImage={settings?.data?.[0]?.news_bg} />

      <div className="container mt-4">
        <Box
          sx={{
            display: 'grid',
            gridTemplateColumns: `repeat(${columns}, 1fr)`,
            gap: 10,
            paddingTop: isMobile ? 2 : 8,
            paddingBottom: isMobile ? 2 : 8,
          }}
        >
          {newsArticles.map((article, index) => (
            <motion.div key={index} variants={cardVariants} 
                        initial="hidden" 
                        whileInView="visible" 
                        viewport={{ once: true, amount: 0.2 }}>
              <a href={`/news/${article.slug}`}
                aria-label="News Details"
                style={{ textDecoration: 'none' }}>
                <Card
                  sx={{
                    position: 'relative',
                    borderRadius: 2,
                    overflow: 'hidden',
                    boxShadow: '0 4px 10px rgba(0, 0, 0, 0.2)',
                    transition: 'transform 0.3s ease-in-out, box-shadow 0.3s ease-in-out',
                    '&:hover': {
                      transform: 'scale(1.03)',
                      boxShadow: '0 6px 15px rgba(0, 0, 0, 0.3)',
                      '& .title': {
                        color: '#feca07',
                      },
                    },
                  }}
                >
                  <CardMedia
                    component="img"
                    image={article.image}
                    alt={article.title}
                    sx={{
                      objectFit: 'cover',
                      height: '450px',
                      width: '100%',
                      transition: 'transform 0.3s ease-in-out',
                      '&:hover': {
                        transform: 'scale(1.1)',
                      },
                    }}
                  />
                  <Box
                    sx={{
                      position: 'absolute',
                      bottom: 0,
                      width: '100%',
                      height: '100%',
                      display: 'flex',
                      flexDirection: 'column',
                      justifyContent: 'flex-end',
                      alignItems: 'flex-start',
                      background: 'rgba(0, 0, 0, 0.5)',
                      color: 'white',
                      padding: 5,
                      borderRadius: 2,
                    }}
                  >
                    <Typography
                      className="title"
                      variant="h6"
                      component="div"
                      sx={{
                        fontWeight: 'bold',
                        fontSize: isMobile ? '1.5rem !important' : isTablet ? '1.75rem  !important' : '1.4rem  !important',
                        textAlign: 'left',
                        lineHeight: 1.4,
                        transition: 'color 0.3s ease-in-out',
                      }}
                    >
                      <p style={{ color: 'white' }}>
                        <i className="fa fa-calendar-check-o" aria-hidden="true"
                            style={{ marginRight: 5, display: 'inline-block' }}></i>
                          {moment(article.date).format('MMM D, YYYY')}
                      </p>
                      {article.title}                      
                    </Typography>
                  </Box>
                </Card>
              </a>
            </motion.div>
          ))}
        </Box>

        {/* Pagination */}
        <Box sx={{ display: 'flex',
                   justifyContent: 'center', 
                   marginTop: 2,
                   marginBottom: 5 }}>
          <Pagination
            count={totalPages} // Total pages
            page={currentPage} // Current page
            onChange={(event, value) => setCurrentPage(value)} // Update current page
            color="primary"
          />
        </Box>
      </div>
    </>
  );
};

const App = () => {
  const theme = createTheme();
  return (
    <ThemeProvider theme={theme}>
      <News />
    </ThemeProvider>
  );
};

export default App;
