import apiClient from "./apiClient";

const memberService = {

    fetchAll: async () => {
        try {
            const response = await apiClient.get('/memberIngos');
            return response.data;
        } catch (error) {
            console.error('Error fetching data:', error);
            throw error;
        }
    } 

}

export default memberService;
