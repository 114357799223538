import React, { useState, useContext } from 'react';
import { Typography } from "@mui/material";
import { SettingsContext } from '../../context/SettingsContext';
import AccessibilityToolbar from '../../pages/AccessibilityToolbar';
import messageService from '../../services/messageService';
export default function Footer() {
  
  const { settings } = useContext(SettingsContext);

  // console.log('Settings', settings)

    // Form state
    const [formData, setFormData] = useState({ name: '', email: '', message: '' });
    const [loading, setLoading] = useState(false);
    const [responseMessage, setResponseMessage] = useState(null);
  
    // Handle input changes
    const handleChange = (e) => {
      setFormData({ ...formData, [e.target.name]: e.target.value });
    };
  
    // Handle form submission
    const handleSubmit = async (e) => {
      e.preventDefault();
      setLoading(true);
      setResponseMessage(null);
  
      try {
        const response = await messageService.sendMessage(formData);
        setResponseMessage({ type: "success", text: response.message });
        setFormData({ name: "", email: "", message: "" }); 
      } catch (error) {
        setResponseMessage({ type: "error", text: error });
      } finally {
        setLoading(false);
      }
    };

  return (
    <footer className="bg-light text-center text-lg-start text-muted">

    <AccessibilityToolbar />   

      <section
        className="d-flex justify-content-center justify-content-lg-between"
        style={{
          position: "relative",
          backgroundImage: 'url("../footer_bg.jpg")', 
          backgroundSize: "cover",
          backgroundPosition: "center",
          width: "100%",
          color: "#fff",
          height: "700px",
          paddingBottom: "50px",
        }}
      >
      
        <div
          style={{
            position: "absolute",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
            backgroundColor: "rgba(0, 0, 0, 0.5)",
            zIndex: 1,
          }}
        ></div>

        <div className="container" style={{ position: "relative", zIndex: 2 }}>

          <Typography
                    variant="h2"
                    color="#f1f1f1"
                    className='mainH2TITLE'
                    sx={{
                      paddingTop: 9,
                      paddingBottom: 7,
                      textTransform: "uppercase",
                      fontWeight: "bold",
                      textAlign: "left",
                    }}
                  >
                    Get in touch with us
                  </Typography>

          <div className="row">
        
          <div className="col-md-6 col-lg-6 col-xl-6 mx-auto mb-4 FooterINFO">
        <p style={{ fontSize: '3em', color: 'white', fontWeight: 'bold' }}>
          <i className="fa fa-map-marker me-4" aria-hidden="true"></i>
            {settings?.data?.[0]?.head_office}
        </p>
        
        <p style={{ fontSize: '1.5em', color: 'white' }}>
          <i className="fa fa-phone me-2"></i> {settings?.data?.[0]?.phone}
        </p>

        <p style={{ fontSize: '1.5em', color: 'white' }}>Lucky Gurung, Secretariat Manager</p>

        <p style={{ fontSize: '1.5em', color: 'white' }}>
          <i className="fa fa-envelope-open-o me-3"></i>
          lucky@ain.org.np
        </p>
        <p style={{ fontSize: '1.5em', color: 'white' }}>Reshma Shrestha, Programme Coordinator</p>

        <p style={{ fontSize: '1.5em', color: 'white' }}>
          <i className="fa fa-envelope-open-o me-3"></i>
          reshma@ain.org.np
        </p>

        <p style={{ fontSize: '1.5em', 
                    color: 'white',
                    marginTop: "10px", 
                    marginBottom: "10px",
                    fontWeight: 'bolder' }}>Follow us on</p>

         <p>
         <div style={{ display: "flex", gap: "10px" }}>
              <a 
                href={settings?.data?.[0]?.facebook} 
                target="_blank" 
                rel="noopener noreferrer"
                aria-label="Visit our Facebook page"
                style={{ textDecoration: "none" }}
              >
                <i
                  className="fa fa-facebook"
                  style={{
                    backgroundColor: "#fff",
                    padding: "10px",
                    borderRadius: "5px",
                    color: "#3b5998",
                    fontSize: "1.2em", // Slightly larger for better visibility
                    display: "inline-flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                ></i>
              </a>

              <a 
                href={settings?.data?.[0]?.youtube} 
                target="_blank" 
                rel="noopener noreferrer"
                aria-label="Visit our YouTube channel"
                style={{ textDecoration: "none" }}
              >
                <i
                  className="fa fa-youtube"
                  style={{
                    backgroundColor: "#fff",
                    padding: "10px",
                    borderRadius: "5px",
                    color: "#ff0000",
                    fontSize: "1.2em", // Slightly larger for better visibility
                    display: "inline-flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                ></i>
              </a>
            </div>

        </p>

      </div>


     
           {/* Contact Form Section */}
           <div className="col-md-6 mx-auto mb-4">
              {responseMessage && (
                <div className={`alert alert-${responseMessage.type === 'success' ? 'success' : 'danger'}`}>
                  {responseMessage.text}
                </div>
              )}
              
              <form onSubmit={handleSubmit}>
                <div className="mb-3">
                  <input
                    type="text"
                    className="form-control footer_input"
                    id="name"
                    name="name"
                    value={formData.name}
                    onChange={handleChange}
                    placeholder="Your Name"
                    required
                  />
                </div>
                <div className="mb-3">
                  <input
                    type="email"
                    className="form-control footer_input"
                    id="email"
                    name="email"
                    value={formData.email}
                    onChange={handleChange}
                    placeholder="Your Email"
                    required
                  />
                </div>
                <div className="mb-3">
                  <textarea
                    className="form-control"
                    id="message"
                    name="message"
                    rows="6"
                    value={formData.message}
                    onChange={handleChange}
                    placeholder="Your Message"
                    required
                  ></textarea>
                </div>
                <button type="submit" className="btn btn-primary" disabled={loading}>
                  {loading ? 'Sending...' : 'Send Message'}
                </button>
              </form>
            </div>
          </div>
        </div>
      </section>

      {/* Footer Content */}
      <section style={{
        backgroundColor: "rgba(0, 0, 0, 0.9)",
      }}>
        <div className="container text-center text-md-start  py-5">
          <div className="row">
          <div
              className="col-md-3 col-lg-3 col-xl-3 mb-4"
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                minHeight: "150px",
              }}
            >
              <img
                src="https://admin.ain.org.np/public//uploads/setting/ain_logo.png"
                style={{ width: "40%" }}
                alt="AIN Logo"
              />
            </div>
            <div className="col-md-2 col-lg-2 col-xl-2 mx-auto mb-4 footerABT">
              <h6 className="text-uppercase fw-bold mb-4">About Us</h6>
            
              <nav aria-label="AIN Navigation">
                <ul style={{ listStyle: "none", padding: 0 }}>
                  <li>
                    <a href="/pages/what-is-ain-video" className="text-reset" aria-label="Watch: What is AIN? - Video">
                      What is AIN? - Video
                    </a>
                  </li>
                  <li>
                    <a href="/pages/message-from-ain-chairperson" className="text-reset" aria-label="Read: Message from Chairperson">
                      Message from Chairperson
                    </a>
                  </li>
                  <li>
                    <a href="/steering-committee" className="text-reset" aria-label="Learn about: Steering Committee">
                      Steering Committee
                    </a>
                  </li>
                  <li>
                    <a href="/members" className="text-reset" aria-label="View Members: INGOs">
                      Members INGOs
                    </a>
                  </li>
                 
                </ul>
              </nav>

              
              
            </div>
            
            <div className="col-md-3 col-lg-2 col-xl-2 mx-auto mb-4 footerABT">
              <h6 className="text-uppercase fw-bold mb-4">Useful links</h6>
             
              <nav aria-label="Quick Links">
                  <ul style={{ listStyle: "none", padding: 0 }}>
                    <li>
                      <a href="/stories" className="text-reset" aria-label="Read Our Stories">
                        Our Stories
                      </a>
                    </li>
                    <li>
                      <a href="/news" className="text-reset" aria-label="View News and Events">
                        News and Events
                      </a>
                    </li>
                    <li>
                      <a href="/vacancy" className="text-reset" aria-label="Explore Career Opportunities">
                        Career
                      </a>
                    </li>
                    <li>
                      <a href="#" className="text-reset" aria-label="Get Help and Support">
                        Help
                      </a>
                    </li>
                  </ul>
                </nav>

              
            </div>

            <div className="col-md-4 col-lg-3 col-xl-3 mx-auto mb-md-0 mb-4 footerABT">
              <h6 className="text-uppercase fw-bold mb-4">LIKE US ON FACEBOOK</h6>
              <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
              <iframe
                  src="https://www.facebook.com/plugins/page.php?href=https%3A%2F%2Fwww.facebook.com%2Fainnepal%2F&tabs=timeline&width=340&height=500&small_header=false&adapt_container_width=true&hide_cover=false&show_facepile=true&appId=1608952235810011"
                  width="100%"
                  height="190"
                  style={{
                    border: "none",
                    overflow: "hidden",
                  }}
                  scrolling="no"
                  frameBorder="0"
                  allowFullScreen
                  allow="autoplay; clipboard-write; encrypted-media; picture-in-picture; web-share"
                  title="Facebook Page Plugin"
                  loading="lazy" // Performance improvement
                ></iframe>

              </div>            
            </div>
          </div>
        </div>
      </section>

      <div className="text-center p-4"
           style={{ backgroundColor: 'rgb(27 27 29)', 
                    color:"rgb(143 149 156)",
                    fontSize: "13px" }}>
            Copyright © 2025 All Rights Reserved
      </div>
    </footer>
  );
}
