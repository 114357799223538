import React, { useState, useEffect, useContext } from "react";
import galleryService from "../../services/galleryServices";
import Breadcrumb from "../Breadcrumb/Breadcrumb";
import { Helmet } from "react-helmet-async";
import { SettingsContext } from "../../context/SettingsContext";
import {
  Box,
  Card,
  CardMedia,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import "bootstrap/dist/css/bootstrap.min.css";
import { motion } from "framer-motion";

const GalleryList = () => {
  const [galleryData, setGalleryData] = useState([]);
  const { settings } = useContext(SettingsContext);

  // Access the theme
  const theme = useTheme();

  // Responsive columns based on breakpoints
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const isTablet = useMediaQuery(theme.breakpoints.between("sm", "md"));
  const isLargeScreen = useMediaQuery(theme.breakpoints.up("lg")); // For PC
  const columns = isMobile ? 1 : isTablet ? 2 : isLargeScreen ? 4 : 3;

  useEffect(() => {
    const loadData = async () => {
      const res = await galleryService.fetchAll();
      setGalleryData(res.data);
    };
    loadData();
  }, []);

  const cardVariants = {
    hidden: { opacity: 0, y: 50 },
    visible: { opacity: 1, y: 0, transition: { duration: 0.6 } },
  };

  return (
    <>
      <Helmet>
        <title>Photo Gallery</title>
      </Helmet>

      <Breadcrumb title="Photo Gallery" backgroundImage={settings?.data?.[0]?.team_bg} />

      <div className="container">
        <Box
          sx={{
            display: "grid",
            gridTemplateColumns: `repeat(${columns}, 1fr)`,
            gap: 3,
            paddingTop: isMobile ? 2 : 8,
            paddingBottom: isMobile ? 2 : 8,
          }}
        >
          {galleryData.map((gallery, index) => (
            <motion.div
              key={index}
              variants={cardVariants}
              initial="hidden"
              whileInView="visible"
              viewport={{ once: true, amount: 0.2 }}
            >
              <a href={`/gallery/${gallery.slug}`} style={{ textDecoration: "none" }}>
                <Card
                  sx={{
                    position: "relative",
                    borderRadius: 2,
                    overflow: "hidden",
                    boxShadow: "0 4px 10px rgba(0, 0, 0, 0.2)",
                    transition: "transform 0.3s ease-in-out, box-shadow 0.3s ease-in-out",
                    "&:hover": {
                      transform: "scale(1.03)",
                      boxShadow: "0 6px 15px rgba(0, 0, 0, 0.3)",
                      "& .title": {
                        color: "#feca07",
                      },
                    },
                  }}
                >
                  <CardMedia
                    component="img"
                    image={gallery.image}
                    alt={gallery.title}
                    sx={{
                      objectFit: "cover",
                      height: "300px",
                      width: "100%",
                      transition: "transform 0.3s ease-in-out",
                      "&:hover": {
                        transform: "scale(1.1)",
                      },
                    }}
                  />
                  <Box
                    sx={{
                      position: "absolute",
                      bottom: 0,
                      width: "100%",
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "flex-end",
                      alignItems: "flex-start",
                      background: "rgba(0, 0, 0, 0.5)",
                      color: "white",
                      padding: 2,
                    }}
                  >
                    <Typography
                      className="title"
                      variant="h6"
                      component="div"
                      sx={{
                        fontWeight: "bold",
                        fontSize: isMobile ? "1rem" : isTablet ? "1.2rem" : "1.4rem",
                        textAlign: "center",
                        lineHeight: 1.4,
                        transition: "color 0.3s ease-in-out",
                      }}
                    >
                      {gallery.title}
                    </Typography>
                  </Box>
                </Card>
              </a>
            </motion.div>
          ))}
        </Box>
      </div>
    </>
  );
};

export default GalleryList;
