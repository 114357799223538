import React from "react";
import { Typography} from '@mui/material';

const donationItems = [
  {
    id: 1,
    bgClass: "bg_one",
    icon: "img/book.png",
    title: "Health & Education",
    link: "about.html",
  },
  {
    id: 2,
    bgClass: "bg_two",
    icon: "img/environment.png",
    title: "Environment",
    link: "about.html",
  },
  {
    id: 3,
    bgClass: "bg_three",
    icon: "img/human_rights.png",
    title: "Human Rights",
    link: "about.html",
  },
  {
    id: 4,
    bgClass: "bg_four",
    icon: "img/disaster_mgmt.png",
    title: "Disaster Management",
    link: "about.html",
  },
];

const About = () => {
  return (
    <>
      {/* First Section */}
      <section id="about_top_area" className="section_padding pb-5 about_front_area">
        <div className="container pb-5">


        <Typography
              variant="h2"
              color={'black'}
              className='mainH2TITLE'
              sx={{
                mb: 0,
                pt: 10,
                pb: 5,
                textTransform: 'uppercase',
                fontWeight: 'bold',
              }}
            >
              WHO WE ARE
            </Typography>


          <div className="row mb-5 pb-5 d-none">
            {donationItems.map((item) => (
              <div key={item.id} className="col-lg-3 col-md-6 col-sm-12 col-12">
                <div className={`about_top_boxed ${item.bgClass}`}>
                  <div className="about_top_boxed_icon">
                    <img src={item.icon} alt="icon" />
                  </div>
                  <div className="about_top_boxed_text">
                    <h3>{item.title}</h3>
                  </div>
                </div>
              </div>
            ))}
          </div>
       
          <div className="row">
            {/* Image Section */}
            <div className="col-lg-5 col-md-12 col-sm-12 col-12">

            <div className="about_area_img" style={{ height: "730px", width: "100%" }}>
                <img 
                  src="img/about.jpg" 
                  alt="About AIN" 
                  style={{
                    width: "100%",
                    height: "100%",
                    objectFit: "cover", // You can change this to 'contain' if you prefer
                  }}
                />
              </div>
            </div>

            {/* Text Section */}
            <div className="col-lg-7 col-md-12 col-sm-12 col-12">
              <div className="about_area_main_text">
                <div className="about_area_para px-4">

                    <h3>ABOUT AIN</h3>
                    <p>
                    AIN is a member-based network of INGOs working in Nepal. AIN members are working on a wide range of issues in several sectors and making noteworthy contributions to humanitarian and development efforts in Nepal. Currently we have 108 active members.
                    </p>
                    <p>
                    AIN aims to be a strong, proactive, and accountable network of INGOs in Nepal that works together with local actors to promote an enabling environment for civil society in Nepal and contribute to sustainable development, including the policy-making process and its implementation under the federal, provincial, and local government bodies. In doing so, 
                    AIN aims to support the humanitarian and development efforts in Nepal, contribute to fulfilling Sustainable Development Goals (SDGs) and other national and international commitments in an inclusive and environmentally friendly way, promote recognition of civil society, including AIN members as a trusted partner with evidence-based contributions towards the development of Nepal, and to contribute to the LDC graduation in 2026.                    </p>
               
                    <h4>AIN’s Strategic Goal</h4>
                    <p>
                    AIN’s goal is to contribute to a prosperous and peaceful Nepal, where people mutually respect diverse cultures and social values and where people live in harmony with nature, for which INGOs contribute to accelerate and strengthen civic participation in the development process in collaboration with CSOs, CBOs, public and private sectors, the Government of Nepal, external development actors, and other key stakeholders.
                    <ul>
                      <li sx={{ marginBottom: "10px" }}>
                        Strengthen a conducive policy and working environment for CSOs and civic actors.
                      </li>
                      <li sx={{ marginBottom: "10px" }}>
                        Support the government of Nepal in achieving its goals.
                      </li>
                      <li sx={{ marginBottom: "10px" }}>
                        Internal strengthening of AIN to become a proactive, trusted, and credible learning and sharing platform of INGOs in Nepal.
                      </li>
                    </ul>
                    </p>
                </div>

                <div className="about_vedio_area">
                  {/* <a href="about.html" className="btn btn_theme btn_md">
                    Learn more
                  </a> */}
                  <a href="/pages/what-is-ain-video" className="read_abt_btn px-4 mx-4">
                    Read More
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default About;
