import apiClient from "./apiClient";

const popupService = {

    fetchAll: async () => {
        try{
            const response = await apiClient.get(`/popup`);
            return response.data;
        } catch (error) {
            console.error('Error fetching data:', error);
            throw error;
        }
    }

}

export default popupService;