import React, { useEffect, useState, useContext } from 'react';
import { Helmet } from 'react-helmet-async';
import Breadcrumb from '../Breadcrumb/Breadcrumb';
import DataTable from 'react-data-table-component';
import { SettingsContext } from '../../context/SettingsContext';
import publicationService from '../../services/publicationService';

const Publication = () => {
  const [publications, setPublications] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [searchQuery, setSearchQuery] = useState('');
  const { settings } = useContext(SettingsContext);


  useEffect(() => {
    const loadData = async() => {
        try {
            const res = await publicationService.fetchAll();
            //console.log('news', res);
            setPublications(res.data);
            setLoading(false); // Set loading to false when data is fetched
        } catch (error) {
            console.error("Error fetching news:", error);
            setLoading(false);
        }
    }
    loadData();
  }, []);



  const handleSearchChange = (event) => {
    setSearchQuery(event.target.value);
  };

  const filteredPulications = publications?.filter((item) =>
    ['title', 'document_name']
      .some(key => item[key]?.toLowerCase().includes(searchQuery.toLowerCase()))
  );

  const columns = [
    {
      name: 'S.N',
      selector: (row, index) => index + 1,
      sortable: false,
      width: '70px',
      style: { textAlign: 'center' },
    },
    {
      name: 'Posted Date',
      selector: row => {
        const date = new Date(row.date);
        const options = { day: '2-digit', month: 'short', year: 'numeric' };
        return `${date.toLocaleDateString('en-GB', options).replace(/(\w+ \w+)(,?)/, '$1,')}`.trim();
      },
      sortable: true,
    },
    
      {
        name: 'Document Title',
        selector: row => (
          <div className="d-flex flex-column align-items-center">
            {row.document && (
              <a
                href={row.document}
                target="_blank"
                rel="noopener noreferrer"
                className="mb-2"
                style={{ minWidth: '100px', textDecoration: 'none' }}
              >
                {row.title}
              </a>
            )}
          </div>
        ),
        sortable: false,
        style: {
          textAlign: 'center',
        },
      },
  ];

  const customStyles = {
    table: {
      style: {
        tableLayout: 'fixed', 
      },
    },
    headCells: {
      style: {
        fontSize: '18px', 
        fontWeight: 'bold', 
        color: '#093f89',
        backgroundColor: '#f5f5f5', 
        textAlign: 'center',
        padding: '12px', 
      },
    },
    cells: {
      style: {
        paddingTop: '30px',
        paddingBottom: '30px',
        fontSize: '16px', 
        textAlign: 'left',
      },
    },
    rows: {
      style: {
        '&:hover': {
          backgroundColor: '#f0f8ff', 
        },
      },
    },
  };

  return (
    <>
      <Helmet>
        <title>Publications</title>
      </Helmet>

      <Breadcrumb title="Publications" backgroundImage={settings?.data?.[0]?.product_bg} />

      <div className="container mt-4" style={{ paddingTop: '40px', paddingBottom: '40px' }}>
        <div className="mb-5 d-flex justify-content-end">
          <div className="input-group" style={{ maxWidth: '300px' }}>
            <span className="input-group-text" style={{ backgroundColor: 'transparent', border: 'none' }}>
              <i className="fa fa-search"></i>
            </span>
            <input
              type="text"
              className="form-control form-control-sm"
              placeholder="Search"
              value={searchQuery}
              onChange={handleSearchChange}
            />
          </div>
        </div>

        {loading ? (
          <div className="text-center">Loading...</div>
        ) : error ? (
          <div className="text-center text-danger">{error}</div>
        ) : filteredPulications && filteredPulications.length > 0 ? (
          <DataTable
            title="Career Opportunities"
            columns={columns}
            data={filteredPulications}
            pagination
            paginationPerPage={20} 
            highlightOnHover
            striped
            responsive
            customStyles={customStyles}
            noHeader
          />
        ) : (
          <div className="text-center">No contents available</div>
        )}
      </div>
    </>
  );
};

export default Publication;
