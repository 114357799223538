import React, { useEffect, useState, useContext } from 'react';
import { useParams } from 'react-router-dom';
import ModuleBreadcrumbs from '../Breadcrumb/ModuleBreadcrumbs';
import moment from 'moment';
import { Helmet } from 'react-helmet-async';
import { SettingsContext } from '../../context/SettingsContext';
import policyService from '../../services/policyServices';

// Define Modal Component
const Modal = ({ isOpen, onClose, children }) => {
  if (!isOpen) return null;

  return (
    <div style={styles.overlay}>
      <div style={styles.modal}>
        <button style={styles.closeButton} onClick={onClose}>
          ×
        </button>
        {children}
      </div>
    </div>
  );
};

const styles = {
  overlay: {
    position: 'fixed',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    zIndex: 1000,
  },
  modal: {
    backgroundColor: '#fff',
    padding: '20px',
    borderRadius: '8px',
    width: '400px',
    maxWidth: '90%',
    boxShadow: '0 5px 15px rgba(0, 0, 0, 0.3)',
    textAlign: 'center',
    position: 'relative',
  },
  closeButton: {
    position: 'absolute',
    top: '10px',
    right: '10px',
    background: 'none',
    border: 'none',
    fontSize: '1.5rem',
    cursor: 'pointer',
  },
};

const PolicyDetail = () => {
  const { slug } = useParams();
  const [content, setContent] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [isPasswordProtected, setIsPasswordProtected] = useState(false);
  const [password, setPassword] = useState('');
  const [hasAttemptedPassword, setHasAttemptedPassword] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const { settings } = useContext(SettingsContext);

  const fetchPolicyBySlug = async (slug, password = null) => {
    try {
      setLoading(true);
      setError(null);
      const policy = await policyService.fetchBySlug(slug, password);

      if (policy.is_password_protected && !password) {
        setIsPasswordProtected(true);
        setIsModalOpen(true); // Open the modal for password input
        setContent(null);
      } else {
        setContent(policy);
        setIsPasswordProtected(false);
        setIsModalOpen(false); // Close the modal if loaded successfully
      }
    } catch (err) {
      if (err.response?.status === 403 || err.response?.status === 401) {
        setError(hasAttemptedPassword ? 'Incorrect password. Please try again.' : null);
        setIsModalOpen(true); // Keep modal open on incorrect password
      } else {
        setError('Failed to load the policy.');
      }
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (slug) {
      setContent(null);
      setPassword('');
      setHasAttemptedPassword(false);
      setIsPasswordProtected(false);
      fetchPolicyBySlug(slug);
    }
  }, [slug]);

  const handlePasswordSubmit = async () => {
    if (!password.trim()) {
      setError('Password is required.');
      return;
    }

    setHasAttemptedPassword(true);
    await fetchPolicyBySlug(slug, password);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  if (loading) return <div>Loading...</div>;

  // if (error && !isPasswordProtected) return <div style={{ color: 'red' }}>Error: {error}</div>;

  return (
    <>
      <Helmet>
        <title>{content?.title || 'Policy'}</title>
      </Helmet>

      <ModuleBreadcrumbs
        title={content?.title || 'Policy'}
        backgroundImage={settings?.data?.[0]?.team_bg}
      />

      <div className="container mt-4">
        {content && (
          <>
            <p>
              <i
                className="fa fa-calendar-check-o"
                aria-hidden="true"
                style={{ marginRight: 5, display: 'inline-block' }}
              ></i>
              {moment(content.created_at).format('MMM D, YYYY')}
            </p>
            <blockquote style={{ marginBottom: 40 }}>
              <h3 style={{ lineHeight: '1.6' }}>{content.content}</h3>
            </blockquote>
          </>
        )}
      </div>

      <Modal isOpen={isModalOpen} onClose={closeModal}>
        <p>Please enter the password to access the content:</p>
        <input
          type="password"
          value={password}
          onChange={(e) => {
            setPassword(e.target.value);
            setError(null); // Clear any existing error while typing
          }}
          placeholder="Enter password"
          style={{
            width: '100%',
            padding: '10px',
            marginBottom: '10px',
            borderRadius: '4px',
            border: '1px solid #ccc',
          }}
        />
        <button
          onClick={handlePasswordSubmit}
          style={{
            padding: '10px 20px',
            backgroundColor: '#007BFF',
            color: '#fff',
            border: 'none',
            borderRadius: '4px',
            cursor: 'pointer',
          }}
        >
          Submit
        </button>
        {error && <p style={{ color: 'red', marginTop: '10px' }}>{error}</p>}
      </Modal>
    </>
  );
};

export default PolicyDetail;
