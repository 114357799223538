import React, { useState, useEffect } from "react";
import { Typography, Card, CardContent } from "@mui/material";
import { motion } from "framer-motion";
import ExploreMoreButton from "../Buttons/ExploreMoreButton";
import vacancyService from "../../services/vacancyServices";

const fadeInVariants = {
  hidden: { opacity: 0, x: 30 },
  visible: { opacity: 1, x: 0, transition: { duration: 0.8 } },
};

const Vacancy = () => {
  const [vacancies, setVacancies] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const loadData = async() => {
        try {
            const res = await vacancyService.fetchFrontVacancy();
            setVacancies(res.data);
            setLoading(false); // Set loading to false when data is fetched
        } catch (error) {
            console.error("Error fetching vacancies:", error);
            setLoading(false);
        }
    }
    loadData();
  }, []);

  if (loading) {
    return <Typography variant="h5">Loading vacancies...</Typography>;
  }

  if (error) {
    return <Typography variant="h5" color="error">{`Error: ${error}`}</Typography>;
  }

  return (
    <section
      className='NewsFrontSection'  
      style={{ width: "100%", backgroundColor: "#fff", padding: "20px 0 40px 0" }}
    >
      <div className="container">
        <Typography
          variant="h2"
          color="black"
          className='mainH2TITLE'
          sx={{
            paddingTop: 0,
            paddingBottom: 9,
            textTransform: "uppercase",
            fontWeight: "bold",
            textAlign: "left",
          }}
        >
          Latest Vacancies
        </Typography>

        <div
          className="VacancyCardFront"
          style={{
            display: "flex",
            flexWrap: "wrap",
            justifyContent: "space-between",
            gap: "20px",
          }}
        >
          {vacancies.map((vacancy) => (
            <motion.div
              className="VacancyFront"
              key={vacancy.id}
              initial="hidden"
              animate="visible"
              variants={fadeInVariants}
              style={{
                flex: "1 1 calc(50% - 30px)",
                maxWidth: "calc(50% - 30px)",
                marginBottom: "20px",
              }}
            >
              <Card
              className="VacancyFront1"
                sx={{
                  boxShadow: 3,
                  borderRadius: 2,
                  backgroundColor: "#093f89",
                  overflow: "hidden",
                  transition: "transform 0.3s",
                  "&:hover": { transform: "scale(1.05)" },
                  padding: "16px",
                }}
              >
                <CardContent
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                    gap: "20px",
                    flexDirection: { xs: "column", sm: "row" },
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                      background: "#ffca08",
                      padding: "20px",
                      borderRadius: '4px',
                    }}
                  >
                    <Typography
                      variant="h3"
                      color="#fff"
                      sx={{ fontWeight: "100", lineHeight: "1" ,fontSize: "3.4rem !important"}}
                    >
                      {new Date(vacancy.posted_at).getDate()}
                    </Typography>
                    <Typography
                      variant="body1"
                      color="#fff"
                      sx={{ fontSize: "1.4rem !important", lineHeight: "1.2" }}
                    >
                      {new Date(vacancy.posted_at).toLocaleString("default", {
                        month: "short",
                      })}
                    </Typography>
                  </div>

                  <div style={{ flex: 1 }}>

                  <Typography
                    variant="body2"
                    color="#d1d1d1"
                    fontSize=".8em"
                    sx={{
                      marginBottom: "8px",
                    }}
                  >
                    Apply Before: {new Date(vacancy.deadline).toLocaleDateString()}
                  </Typography>



                    <Typography
                    className="VacancyFront1"
                      variant="body2"
                      color="#f1f1f1"
                      fontSize="1.2em !important"
                      sx={{
                        marginBottom: "8px",
                      }}
                    >
                      {vacancy.company_name}
                    </Typography>

                    <Typography
                    className="VacancyFront1"
                      variant="h4"
                      component="div"
                      fontWeight="100"
                      color="#fff"
                      sx={{
                        fontSize: { xs: "1.2rem !important", sm: "1.5rem  !important" },
                      }}
                    >
                      {vacancy.title}
                    </Typography>
                  </div>

                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                    }}
                  >
                    <a
                    className="VacancyFront1"
                      href={vacancy.url || vacancy.document || "#"}
                      target="_blank"
                      rel="noopener noreferrer"
                      aria-label="View Vacancy Detail"
                      style={{
                        textDecoration: "none",
                        display: "inline-flex",
                        alignItems: "center",
                        justifyContent: "center",
                        width: "50px",
                        height: "50px",
                        borderRadius: "50%",
                        backgroundColor: "#fff",
                        color: "#003153",
                        boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)",
                      }}
                    >
                      <i
                        className="fa fa-long-arrow-right"
                        style={{
                          fontSize: "19px !important",
                          lineHeight: "1",
                          textAlign: "center",
                        }}
                        aria-hidden="true"
                      ></i>
                      {/* Alternative text for screen readers */}
                      <span className="sr-only">Go to vacancy details</span>
                    </a>
                  </div>
                </CardContent>
              </Card>
            </motion.div>
          ))}
        </div>

        <div className="d-flex justify-content-end mt-4" style={{ paddingBottom: "45px" }}>
          <ExploreMoreButton
            href="/vacancy"
            label="Explore More"
            color="black"
            hoverColor="#f39c12"
          />
        </div>
      </div>
    </section>
  );
};

export default Vacancy;
