import React, { useEffect, useState, useContext} from 'react';
import { Helmet } from 'react-helmet-async';
import Breadcrumb from '../components/Breadcrumb/Breadcrumb';
import DataTable from 'react-data-table-component'; 
import { SettingsContext } from '../context/SettingsContext';
import steeringCommitteeService from '../services/steeringCommitteeServices';



const SteeringCommittee = () => {

  const [content, setContent] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [searchQuery, setSearchQuery] = useState(''); 
  const { settings } = useContext(SettingsContext);

  useEffect(() => {
    const loadData = async() => {
      const res = await steeringCommitteeService.fetchAll();
      setContent(res.data);
    }
    loadData();
  }, [])

  const handleSearchChange = (event) => {
    setSearchQuery(event.target.value);
  };

  const filteredContent = content?.filter((item) =>
    (item.name?.toLowerCase().includes(searchQuery.toLowerCase()) || '') ||
    (item.designation?.toLowerCase().includes(searchQuery.toLowerCase()) || '') ||
    (item.organization?.toLowerCase().includes(searchQuery.toLowerCase()) || '')
  );

  console.log('Filtered Content:', filteredContent);

  const columns = [
    {
      name: 'S.N',
      selector: (row, index) => index + 1,
      sortable: true,
      width: "90px",
      textAlign: "center",
      style: {
        width: '50px',
        textAlign: 'center',
        padding: '0 5px',
      },
    },
    {
      name: 'Name',
      selector: row => (
        <a href={`/steering-committee/${row.slug}`} className='steeing_commiittee_a'  rel="noopener noreferrer">
          {row.name}
        </a>
      ),
      sortable: true,
      wrap: true,
      style: {
        width: '200px',
        textOverflow: 'clip', 
        whiteSpace: 'normal', 
        overflow: 'visible', 
        textAlign: 'left',
      },
    },
    {
      name: 'Designation',
      selector: row => row.designation,
      sortable: true,
      wrap: true,
      style: {
        width: '150px',
        wordWrap: 'break-word', 
        whiteSpace: 'normal', 
        overflow: 'visible', 
        textOverflow: 'clip', 
        textAlign: 'left',
      },
    },
    {
      name: 'Organization',
      selector: row => row.organization,
      sortable: true,
      wrap: true,
      style: {
        width: '200px', 
        wordWrap: 'break-word', 
        whiteSpace: 'normal', 
        overflow: 'visible', 
        textOverflow: 'clip',
        textAlign: 'left',
      },
    },
    {
      name: 'Photo',
      selector: row => (
        <a href={`/steering-committee/${row.slug}`} rel="noopener noreferrer">
          <img 
            src={row.image} 
            alt={row.name} 
            width="100"
            style={{
              width: '125px',
              height: '125px',
              borderRadius: '50%'
            }} 
          />
        </a>
      ),
      sortable: false,
      style: {
        width: '100px',
        textAlign: 'left',
      },
    },
  ];

  const customStyles = {
    table: {
      style: {
        tableLayout: 'fixed', 
      },
    },
    headCells: {
      style: {
        fontSize: '18px', 
        fontWeight: 'bold', 
        color: '#093f89',
        backgroundColor: '#f5f5f5', 
        textAlign: 'center',
        padding: '12px', 
      },
    },
    cells: {
      style: {
        paddingTop: '30px',
        paddingBottom: '30px',
        fontSize: '16px', 
        textAlign: 'left',
      },
    },
    rows: {
      style: {
        '&:hover': {
          backgroundColor: '#f0f8ff', 
        },
      },
    },
  };


 
  return (
    <>
      <Helmet>
        <title>Steering Committee</title>
      </Helmet>

      <Breadcrumb title='Steering Committee' backgroundImage={settings?.data?.[0]?.team_bg} />

      <div className="container mt-4" style={{ paddingTop: "40px", paddingBottom: "40px" }}>
        <div className="mb-5 d-flex justify-content-end">
          <div className="input-group" style={{ maxWidth: '300px' }}>
            <div className="input-group-prepend">
              <span className="input-group-text" style={{ backgroundColor: 'transparent', border: 'none' }}>
                <i className="fa fa-search"></i>
              </span>
            </div>
            <input
              type="text"
              className="form-control form-control-sm"
              placeholder="Search"
              value={searchQuery}
              onChange={handleSearchChange}
            />
          </div>
        </div>

        {filteredContent && filteredContent.length > 0 ? (
          <DataTable
            title="Steering Committee"
            columns={columns}
            data={filteredContent}
            pagination={false}
            paginationPerPage={20} 
            highlightOnHover
            striped
            responsive
            customStyles={customStyles}
            noHeader
          />
        ) : (
          <div className="text-center"></div>
        )}
      </div>
    </>
  );
};

export default SteeringCommittee;
