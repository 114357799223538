import axios from 'axios';

// Create a shared Axios instance
const apiClient = axios.create({
  baseURL: window.CONFIG.BASE_URL, // Replace with your API URL
  headers: {
    'Content-Type': 'application/json',
  },
});


// Optional: Add interceptors if needed
apiClient.interceptors.request.use(
  (config) => {
    // You can add authorization tokens or other headers here
    const token = localStorage.getItem('authToken');
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

apiClient.interceptors.response.use(
  (response) => response,
  (error) => {
    // Handle global errors here (e.g., show a toast or redirect on 401)
    return Promise.reject(error);
  }
);

export default apiClient;
