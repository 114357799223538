import apiClient from './apiClient';

const messageService = {
  sendMessage: async (formData) => {
    try {
      const response = await apiClient.post('/save-form', formData);
      return response.data;
    } catch (error) {
      console.error('Error sending message:', error);
      throw error;
    }
  },
};

export default messageService;