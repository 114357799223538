import apiClient from "./apiClient";

const newsServices = {

    fetchFrontNews: async () => {
        try {
            const response = await apiClient.get('/homepage-news');
            return response.data;
        }  catch (error) {
            console.error('Error fetching data:', error);
            throw error;
        }
    },
   
    fetchAll: async (page = 1) => {
        try {
          const response = await apiClient.get(`/news?page=${page}`);
          return response.data;
        } catch (error) {
          console.error('Error fetching data:', error);
          throw error;
        }
      },  

    fetchBySlug: async (slug) => {
        try {
            const response = await apiClient.get(`/news/${slug}`);
            return response.data;
        } catch (error) {
            console.error('Error fetching data by slug:', error);
            throw error;
        }
    }

}

export default newsServices