import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import galleryService from "../../services/galleryServices";
import Breadcrumb from "../Breadcrumb/Breadcrumb";
import LightGallery from "lightgallery/react";

// Import styles
import "lightgallery/css/lightgallery.css";
import "lightgallery/css/lg-zoom.css";
import "lightgallery/css/lg-thumbnail.css";

// Import plugins
import lgThumbnail from "lightgallery/plugins/thumbnail";
import lgZoom from "lightgallery/plugins/zoom";

const GalleryDetail = () => {
  const { slug } = useParams();
  const [gallery, setGallery] = useState(null);
  const [galleryImages, setGalleryImages] = useState([]);

  useEffect(() => {
    if (!slug) {
      console.error("Slug is undefined. Check the URL.");
      return;
    }

    const loadGallery = async () => {
      try {
        console.log("Fetching gallery for slug:", slug);
        const data = await galleryService.fetchBySlug(slug);
        console.log("API response:", data);

        if (!data.gallery) {
          console.error(`Gallery not found for slug: ${slug}`);
          return;
        }

        setGallery(data.gallery);
        setGalleryImages(data.images || []);
      } catch (error) {
        console.error("Failed to fetch gallery details:", error);
      }
    };

    loadGallery();
  }, [slug]);

  if (!gallery) {
    return ;
  }

  return (
    <div>
      <Breadcrumb 
                title={gallery.title}
                backgroundImage={gallery.image}  />
      <div className="container py-4">
        <LightGallery 
                 speed={500} 
                 plugins={[lgThumbnail, lgZoom]}
                mode="lg-fade"
                pager={false}
                thumbnail={true}
                mobileSettings={{
                    controls: false,
                    showCloseIcon: false,
                    download: false,
                    rotate: false,  
                }}
                 
                 >
          {galleryImages.map((image, index) => (
            <a href={image.image} key={index}>
              <img
                src={image.image}
                alt={gallery.title}
                style={{
                  width: "200px",
                  height: "200px",
                  objectFit: "cover",
                  margin: "10px",
                  borderRadius:"5px",
                  marginBottom: "35px",
                  marginTop: "35px"
                }}
              />
            </a>
          ))}
        </LightGallery>
      </div>
    </div>
  );
};

export default GalleryDetail;
